import React from 'react';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import {
    container,
    chocolateImage,
    marquee,
    serving,
    recipesSection,
    inspiration,
    caramelServing,
    wedelLogo,
} from './template.module.scss';
import { IPage } from '../models/page.model';
import { ImageDataLike } from '../models/image-data-like.model';
import { IWedelServing } from './components/serving-card';
import { IQueryAllResult } from '../models/query-all-result.model';
import { IWedelRecipe } from './components/recipe-card';
import { getNodes } from '../utils/get-nodes';

import SEO from '../components/seo';
import Header from './components/header';
import Hero from './components/hero';
import Product, { IProductWedel } from './components/product';
import Interlude from './components/interlude';
import Marquee from './components/marquee';
import MarqueeItem from './components/marquee-item';
import Serving from './components/serving';
import Recipes, { IWedelRecipeOption } from './components/recipes';
import Inspiration from './components/inspiration';
import Footer from './components/footer';
import ServingDisclaimer from './components/serving-disclaimer';

interface ISoplicaWedelProps {
    readonly data: {
        page: IPage | null;
        allServingImg: IQueryAllResult<ImageDataLike>;
        allRecipeImg: IQueryAllResult<ImageDataLike>;
    };
}

const SoplicaWedel: React.FC<ISoplicaWedelProps> = ({ data }) => {
    const { page, allServingImg, allRecipeImg } = data;

    const caramelProduct: IProductWedel = {
        name: 'Soplica Słony karmel',
        subName: (
            <>
                Z dodatkiem białej czekolady od{' '}
                <span className={wedelLogo}>
                    <StaticImage
                        src="../assets/images/soplica-wedel/logo-wedel.png"
                        alt="Wedel"
                        quality={100}
                    />
                </span>
            </>
        ),
        description:
            'Najwyższej jakości likier Soplica wzbogacony smakiem aksamitnej wedlowskiej białej czekolady to niezwykle interesująca propozycja dla osób ceniących niebanalne kompozycje smakowe. Kremowa konsystencja, słodko-słony smak to uczta dla podniebienia i przyjemność do ostatniej kropli.',
        serving:
            'Likierem można delektować się prosto z kieliszka lub może on stanowić doskonały dodatek do koktajli, deserów, kawy czy gorącej czekolady.',
        voltage: '15%',
        capacity: '500 ml',
        imgSrc: '/images/soplica-wedel-product-caramel.png',
    };

    const chocolateProduct: IProductWedel = {
        name: 'Soplica Czekoladowa',
        subName: (
            <>
                Z dodatkiem czekolady od{' '}
                <span className={wedelLogo}>
                    <StaticImage
                        src="../assets/images/soplica-wedel/logo-wedel.png"
                        alt="Wedel"
                        quality={100}
                    />
                </span>
            </>
        ),
        description:
            'Najwyższej jakości likier Soplica wzbogacony smakiem aksamitnej wedlowskiej czekolady to gwarancja niepowtarzalnego smaku, która podbije serca wielbicieli słodkich nut.',
        serving:
            'Likierem można delektować się prosto z kieliszka, ale doskonale sprawdzi się również jako dodatek do deserów, kawy czy koktajli.',
        voltage: '15%',
        capacity: '500 ml',
        imgSrc: '/images/soplica-wedel-product-chocolate.png',
    };

    const servingImages = getNodes(allServingImg);
    const servings: IWedelServing[] = [
        { image: servingImages[0], type: 'shot', text: 'W kieliszku' },
        { image: servingImages[1], type: 'drink', text: 'W drinku' },
        { image: servingImages[2], type: 'dessert', text: 'Do deserów' },
        { image: servingImages[3], type: 'coffee', text: 'Do kawy' },
        { image: servingImages[4], type: 'chocolate', text: 'Do czekolady do picia' },
    ];

    const recipeOptions: IWedelRecipeOption[] = [
        { label: 'Wszystkie przepisy', value: 'all' },
        { label: 'Drink', value: 'drink' },
        { label: 'Shot', value: 'shot' },
        { label: 'Deser', value: 'dessert' },
        { label: 'Kawa', value: 'coffee' },
        { label: 'Czekolada do picia', value: 'chocolate' },
    ];

    const recipeImages = getNodes(allRecipeImg);
    const recipes: IWedelRecipe[] = [
        {
            name: 'Karmelowa Królowa',
            type: 'drink',
            image: recipeImages[0],
            ingredients: [
                '40 ml Soplica Słony Karmel',
                '20 ml Soplica Szlachetna Premium',
                '20 ml śmietanka 30%',
                '20 ml mleko kokosowe',
            ],
            preparation:
                'Wszystkie składniki wstrząśnij w shakerze z lodem i odcedź do przygotowanego kieliszka.',
            garnish: 'pokruszony cukierek krówka.',
        },
        {
            name: 'Kawowe przebudzenie',
            type: 'drink',
            image: recipeImages[8],
            ingredients: [
                '40 ml Soplica Czekoladowa',
                '20 ml likieru kawowego Soplica',
                'Espresso',
                '20 ml śmietanka 30%',
            ],
            preparation:
                'Wszystkie składniki wstrząśnij w shakerze z lodem i odcedź do przygotowanego kieliszka.',
            garnish: 'wiórki czekolady.',
        },
        {
            name: 'Słodko-słona niespodzianka',
            type: 'drink',
            image: recipeImages[1],
            ingredients: [
                '40 ml Soplica Słony Karmel',
                '30 ml wódka waniliowa BOLS',
                'Espresso',
                '5 ml Syrop popcornowy lub waniliowy',
            ],
            preparation:
                'Wszystkie składniki wstrząśnij w shakerze z lodem i odcedź do przygotowanego kieliszka.',
            garnish: 'popcorn.',
        },
        {
            name: 'Czekoladowa Rozkosz',
            type: 'drink',
            image: recipeImages[7],
            ingredients: [
                '40ml Soplica Czekoladowa',
                '20ml wódka waniliowa BOLS',
                '20ml śmietanki 30%',
                '20ml mleka',
            ],
            preparation:
                'Wszystkie składniki wstrząśnij w shakerze z lodem i odcedź do przygotowanego kieliszka.',
            garnish: 'mini biszkopty.',
        },
        {
            name: 'Miętowy szept',
            type: 'drink',
            image: recipeImages[9],
            ingredients: [
                '40 ml Soplica Czekoladowa',
                '8-10 listków świeżej mięty',
                '40 ml śmietanka 30%',
                '40 ml mleko',
            ],
            preparation:
                'Wszystkie składniki wstrząśnij w shakerze z lodem i odcedź do przygotowanego kieliszka.',
            garnish: 'wiórki czekolady i listek mięty.',
        },
        {
            name: 'Kokosowy puch',
            type: 'shot',
            image: recipeImages[11],
            ingredients: ['30 ml Soplica Czekoladowa', '10 ml mleko kokosowe'],
            preparation: 'Wlej likier do kieliszka, następnie mleczko kokosowe. ',
            garnish: 'wiórki czekolady i kokosowe.',
        },
        {
            name: 'Czekoladowy moment',
            type: 'shot',
            image: recipeImages[10],
            ingredients: ['20 ml Soplica Czekoladowa', 'Espresso'],
            preparation:
                'Wlej likier do kieliszka, następnie delikatnie po odwróconej łyżeczce dolej espresso jednocześnie uważając, aby obydwie warstwy się nie zmieszały.',
            garnish: 'bita śmietana, likier.',
        },
        {
            name: 'Ekspresowy całus',
            type: 'shot',
            image: recipeImages[2],
            ingredients: ['20 ml Soplica Słony Karmel', 'Espresso'],
            preparation:
                'Wlej likier do kieliszka, następnie delikatnie po odwróconej łyżeczce dolej espresso jednocześnie uważając, aby obydwie warstwy się nie zmieszały.',
            garnish: 'bita śmietana, likier.',
        },
        {
            name: 'Orzechowa Fantazja',
            type: 'dessert',
            image: recipeImages[13],
            ingredients: [
                '60 ml Soplica Czekoladowa',
                '100 g lody śmietankowe',
                'Banan',
                '15 szt. orzechy laskowe',
                '60 ml mleko kokosowe',
            ],
            preparation: 'Wszystkie składniki zblenduj w mikserze, przelej do wysokiej szklanki.',
            garnish: 'bita śmietana, wiórki czekolady, orzechy laskowe, wisienka.',
        },
        {
            name: 'Bananowy raj',
            type: 'dessert',
            image: recipeImages[4],
            ingredients: [
                '60 ml Soplica Słony Karmel',
                '100 g Lody słony karmel lub waniliowe',
                '60 ml mleko',
                'Espresso',
                '1 Banan',
            ],
            preparation: 'Wszystkie składniki zblenduj w mikserze, przelej do wysokiej szklanki.',
            garnish: 'bita śmietana, likier.',
        },
        {
            name: 'Magiczna pistacja',
            type: 'dessert',
            image: recipeImages[12],
            ingredients: [
                '60 ml Soplica Czekoladowa',
                '100 g lody śmietankowe',
                '20 szt. pistacji',
                '60 ml mleka',
                'kilka kostek czekolady mlecznej',
            ],
            preparation: 'Wszystkie składniki zblenduj w mikserze, przelej do wysokiej szklanki.',
            garnish: 'bita śmietana, wiórki czekolady, pistacje, wisienka.',
        },
        {
            name: 'Mroźna przyjemność',
            type: 'dessert',
            image: recipeImages[3],
            ingredients: [
                '60 ml Soplica Słony Karmel',
                '60 ml mleko',
                'kilka ciastek kakaowych z nadzieniem waniliowy',
            ],
            preparation: 'Wszystkie składniki zblenduj w mikserze, przelej do wysokiej szklanki.',
            garnish: 'bita śmietana, pokruszone ciasteczka, wisienka.',
        },
        {
            name: 'Latte Czekoladowa Mgła',
            type: 'coffee',
            image: recipeImages[15],
            ingredients: ['40 ml Soplica Czekoladowa', 'kawa', 'spienione mleko'],
            preparation:
                'Do szklanki wlej likier Soplica. Dodaj spienione mleko, delikatnie wlej kawę.',
            garnish: 'wiórki czekolady.',
        },
        {
            name: 'Latte Karmelowe Marzenie',
            type: 'coffee',
            image: recipeImages[6],
            ingredients: ['40 ml Soplica Słony Karmel', 'Kawa', 'Spienione mleko'],
            preparation:
                'Do szklanki wlej likier Soplica. Dodaj spienione mleko, delikatnie wlej kawę.',
        },
        {
            name: 'Romans w piankach',
            type: 'chocolate',
            image: recipeImages[14],
            ingredients: [
                '40 ml Soplica Czekoladowa',
                '200 ml mleko',
                '6 kostek mlecznej czekolady',
                '1 łyżka czekolady w proszku',
            ],
            preparation:
                'Podgrzewaj wszystkie składniki, aż do rozpuszczenia czekolady. Przelej do szklanki.',
            garnish: 'bita śmietana, mini pianki.',
        },
        {
            name: 'Karmelowe rozgrzanie',
            type: 'chocolate',
            image: recipeImages[5],
            ingredients: [
                '40 ml Soplica Słony Karmel',
                '200 ml mleko',
                '6 kostek białej czekolady karmelowej Wedla',
                '1 cukierek typu krówka',
            ],
            preparation:
                'Podgrzewaj wszystkie składniki, aż do rozpuszczenia czekolady i krówki. Przelej do szklanki.',
            garnish: 'bita śmietana, likier.',
        },
    ];

    return (
        <>
            <SEO meta={page?.meta} />
            <div className={container}>
                <Header />
                <Hero />
                <Product id="liquors" product={chocolateProduct} imageClassName={chocolateImage} />
                <Interlude />
                <Product product={caramelProduct} isReverse={true}>
                    <ServingDisclaimer className={caramelServing} />
                </Product>
                <Marquee className={marquee}>
                    <MarqueeItem />
                </Marquee>
                <Serving id="serving" className={serving} servingCards={servings} />
                <Recipes
                    id="recipes"
                    className={recipesSection}
                    options={recipeOptions}
                    recipes={recipes}
                />
                <Inspiration className={inspiration} />
                <Footer />
            </div>
        </>
    );
};

export const query = graphql`
    query ($language: String!, $site: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        page: strapiPage(
            locale: { eq: $language }
            site: { eq: $site }
            isDummyContent: { eq: false }
        ) {
            ...pageFields
        }
        allServingImg: allFile(
            filter: {
                relativePath: {
                    in: [
                        "soplica-wedel/serving-01-shot.jpg"
                        "soplica-wedel/serving-02-drink.jpg"
                        "soplica-wedel/serving-03-dessert.jpg"
                        "soplica-wedel/serving-04-coffee.jpg"
                        "soplica-wedel/serving-05-chocolate.jpg"
                    ]
                }
            }
            sort: { fields: relativePath, order: ASC }
        ) {
            edges {
                node {
                    childImageSharp {
                        gatsbyImageData(placeholder: BLURRED, quality: 100, width: 500)
                    }
                }
            }
        }
        allRecipeImg: allFile(
            filter: {
                relativePath: {
                    in: [
                        "soplica-wedel/recipe-01-caramel-queen.jpg"
                        "soplica-wedel/recipe-02-sweet-salty-surprise.jpg"
                        "soplica-wedel/recipe-03-express-kiss.jpg"
                        "soplica-wedel/recipe-04-frosty-pleasure.jpg"
                        "soplica-wedel/recipe-05-banana-fantasy.jpg"
                        "soplica-wedel/recipe-06-caramel-warming.jpg"
                        "soplica-wedel/recipe-07-latte-caramel-dream.jpg"
                        "soplica-wedel/recipe-08-chocolate-delight.jpg"
                        "soplica-wedel/recipe-09-coffee-awakening.jpg"
                        "soplica-wedel/recipe-10-mint-whisper.jpg"
                        "soplica-wedel/recipe-11-chocolate-moment.jpg"
                        "soplica-wedel/recipe-12-coconut-fluff.jpg"
                        "soplica-wedel/recipe-13-magic-pistachio.jpg"
                        "soplica-wedel/recipe-14-nutty-fantasy.jpg"
                        "soplica-wedel/recipe-15-romance-in-marshmallows.jpg"
                        "soplica-wedel/recipe-16-latte-chocolate-mist.jpg"
                    ]
                }
            }
            sort: { fields: relativePath, order: ASC }
        ) {
            edges {
                node {
                    childImageSharp {
                        gatsbyImageData(placeholder: BLURRED, quality: 100, width: 500)
                    }
                }
            }
        }
    }
`;

export default SoplicaWedel;
